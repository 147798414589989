<template>
  <div>
    <vx-card>
      <div class="mb-8" >
        <h2 class="mb-0">About Us List</h2>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col md:w-1/2 w-full" align="left">
          <vs-input
            class="w-full search-input mb-5"
            v-model="searchQuery"
            placeholder="Search"
            icon-after="true"
            label-placeholder="Search"
            icon="icon icon-search"
            icon-pack="feather"
          />
        </div>
        <div class="vx-col md:w-1/2" align="right">
          <vs-button  class="m-5"
                     align="right"
                     @click="addAboutUs"
          >Add New About Us
          </vs-button>
        </div>
      </div>

      <div id="data-list-list-view" class="data-list-container" v-if="contents.length">
        <vs-table
          ref="table"
          :sst="true"
          :data="contents"
          :max-items= 50
          pagination
        >
          <template slot="thead">
            <vs-th>Learning Centre</vs-th>
            <vs-th class="action-col justify-center">Action</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].fullName">
                {{ data[indextr].learningCenterDetails.name | capitalize }}
              </vs-td>
              <vs-td :data="data[indextr]._id" class="text-center">
                <a href="#" @click.stop="editDetailHandler(data[indextr], data[indextr].learningCenterId)">
                  <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current"  />
                </a>
               <!-- <a href="#" @click.stop="deleteHandler(data[indextr]._id)">
                  <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2"  />
                </a>-->
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
      <div v-else>
        <p>No data found</p>
      </div>
    </vx-card>
  </div>
</template>

<script>
  import vSelect from "vue-select";
  import { mapActions } from "vuex";
  import Swal from 'sweetalert2/dist/sweetalert2';
  import 'sweetalert2/src/sweetalert2.scss'

  export default {
    components: {
      "v-select": vSelect
    },
    data() {
      return {
        isMounted: false,
        totalDocs: 0,
        currentPage: 1,
        dataTableParams: {
          search: "",
          sort: "learningCenterDetails.name",
          dir: "asc",
          page: 1,
          limit: 20
        },
        searchQuery: "",
        awaitingSearch: false,
        serverResponded: false,
        contents: [],
        limitOptions: [
          { text: "10", value: 5 },
          { text: "20", value: 10 },
          { text: "30", value: 25 },
          { text: "50", value: 50 },
          { text: "100", value: 100 }
        ],
      };
    },
    methods: {
      ...mapActions("aboutUs", [
        "getDirectorAboutUs",
      ]),
      getAboutUsList() {
        this.$vs.loading();
        this.dataTableParams.search = this.searchQuery;
        this.getDirectorAboutUs(this.dataTableParams.search).then(res => {
          this.contents = res.data.data;
          this.$vs.loading.close();
        });
      },
      addAboutUs() {
        this.$router.push({ name: 'about-us-create-director'});
      },
      editDetailHandler(data, id) {
        this.$router.push({ name: 'about-us-details-director', params: { id: id }});
      },
      deleteHandler(id) {
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.value) {
            this.acceptDeleteAlert(id)
          }
        })

      },
      acceptDeleteAlert(id) {
        this.$vs.loading()
        this.deleteTestimonial(id)
          .then(()   => {
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'Success',
              text: "About Us Deleted Successfully.",
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'success'
            })
            this.getTestimonialList();
          })
          .catch(err => {
            this.$vs.loading.close()
          })
      },
    },
    watch: {
        searchQuery: function (val) {
          if (!this.awaitingSearch) {
            setTimeout(() => {
              this.getAboutUsList();
              this.awaitingSearch = false;
            }, 1000); // 1 sec delay
          }
          this.awaitingSearch = true;
        },
    },
    created() {
      this.getAboutUsList();
    }
  };
</script>
